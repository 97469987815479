<template>
  <div class="d-flex flex-column">
    <module-navigation-bar title="Module Assembly">
      <template #module-nav-logo>
        <img
            v-if="isDaiwaLicense"
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
          height="40px"
          alt=""
        />
      </template>
    </module-navigation-bar>
    <div
      v-if="status === 'success'"
      style="height: calc(100% - 46px)"
      class="d-flex"
    >
      <div
        class="py-5 background-white ant-border-right"
        style="min-width: 250px; max-width: 300px"
      >
        <div class="d-flex align-center mb-5 px-5">
          Assembly Hall
          <v-spacer />
          <v-chip class="ml-2">{{ hall.hall }}</v-chip>
        </div>
        <v-list>
          <v-list-item-group
            v-model="focusedLocationIndex"
            color="primary"
            mandatory
          >
            <v-list-item
              v-for="location in locations"
              :key="location.id"
              three-line
              class="d-flex"
            >
              <div class="mr-4 d-flex align-center justify-center">
                {{ location.order }}
              </div>
              <v-list-item-content>
                <v-list-item-title>
                  {{ location.assembly_location }}
                </v-list-item-title>
                <v-list-item-subtitle
                  >{{ location.description }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-chip
                    v-for="type in getTypesByLocation(location.id)"
                    :key="type.id"
                    x-small
                    >{{ type.type }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
      <div class="pa-5 d-flex flex-grow-1">
        <dynamic-data-table
          :table-records="records"
          :table-headers="elementHeaders"
          auto-sort-column="module_type"
          table-title="Elements"
          :items-per-page="-1"
          class="background-white flex-grow-1"
          :is-loading="tableIsLoading"
        >
          <template #table-actions>
            <v-combobox
              v-model="filteredModuleTypes"
              deletable-chips
              chips
              filled
              placeholder="Module type"
              dense
              hide-details
              class="mr-2"
              :items="uniqueModuleTypes"
              multiple
            />
            <v-menu
              v-model="generateMenu"
              :close-on-content-click="false"
              bottom
              nudge-bottom="35"
            >
              <template #activator="{ on, attrs }">
                <v-btn v-bind="attrs" color="primary" elevation="0" v-on="on"
                  >Generate
                </v-btn>
              </template>

              <v-card width="500">
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        >This action will generate the sequence based on the
                        position
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >For example position 2.1 will result in sequence 21
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-list>
                  <v-list-item>
                    <v-list-item-action>
                      <v-switch
                        v-model="generateAndOverwrite"
                        color="primary"
                      ></v-switch>
                    </v-list-item-action>
                    <v-list-item-title
                      >{{
                        generateAndOverwrite
                          ? 'Generate/overwrite all values'
                          : 'Generate only empty values'
                      }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-checkbox v-model="generateDoubleCheck" />
                    </v-list-item-action>
                    <v-list-item-title
                      >Check this box when you are ready
                    </v-list-item-title>
                  </v-list-item>
                </v-list>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    text
                    color="error"
                    :disabled="isGenerating"
                    @click="closeGenerateMenu"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="primary"
                    :disabled="!generateDoubleCheck || isGenerating"
                    :loading="isGenerating"
                    elevation="0"
                    @click="generateByPosition"
                  >
                    Generate
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </template>
          <template #item.module_type="{ item }">
            <td
              class="text-decoration-underline text-primary c-pointer"
              @click="fetchDrawing(item.module_type)"
            >
              <v-icon
                v-if="item.module_type === previewType"
                color="primary"
                small
                class="mr-2"
                >mdi-circle</v-icon
              >
              {{ item.module_type }}
            </td>
          </template>
          <template #item.assembly_sequence="{ item }">
            <div class="editable-field">
              <v-edit-dialog
                :return-value.sync="item.assembly_sequence"
                @save="saveSequence(item)"
              >
                {{ item.assembly_sequence }}
                <template #input>
                  <v-text-field
                    v-model="item.assembly_sequence"
                    placeholder="Sequence"
                    single-line
                    type="number"
                    :min="0"
                  />
                </template>
              </v-edit-dialog>
              <v-icon class="edit-icon" small> mdi-pencil</v-icon>
            </div>
          </template>
        </dynamic-data-table>

        <iframe
          v-if="documentPreview"
          class="ml-15"
          :src="documentPreview"
          type="application/pdf"
          height="100%"
          width="50%"
          style="max-width: 50%"
        />`
      </div>
    </div>
    <div v-else class="d-flex justify-center align-center full-height">
      <ant-loading />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import AntLoading from '@/components/AntLoading.vue';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';
import DynamicDataTable from '@/components/DynamicDataTable.vue';
import {
  createRecordV2,
  downloadDocumentV2,
  importRecordsV2,
  updateRecordV2,
} from '@/services/api/v2/records.v2.api';
import { bookOfObjects } from '@/services/bookOf';
import { executeCustomModuleCall } from '@/services/api/module.api';

export default {
  name: 'DhmeModuleAssembly',
  components: {
    DynamicDataTable,
    AntLoading,
    ModuleNavigationBar,
  },
  data: () => {
    return {
      focusedLocationIndex: null,
      tableRecords: [],
      elementHeaders: [
        { text: 'Module Type', value: 'module_type', hasSlot: true },
        { text: 'Type', value: 'element_category' },
        { text: 'Position', value: 'element_position' },
        { text: 'Sequence', value: 'assembly_sequence', hasSlot: true },
      ],
      elementSequenceTableId: null,
      tableIsLoading: false,
      generateMenu: false,
      generateAndOverwrite: false,
      generateDoubleCheck: false,
      isGenerating: false,

      filteredModuleTypes: [],
      documentPreview: null,
      previewType: null,
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      status: 'dhmeModuleAssembly/moduleStatus',
      locations: 'dhmeModuleAssembly/locations',
      types: 'dhmeModuleAssembly/locationTypes',
      hall: 'dhmeModuleAssembly/assemblyHall',
      isDaiwaLicense: 'isDaiwaLicense',
    }),

    focusedLocation() {
      return this.locations[this.focusedLocationIndex];
    },

    uniqueModuleTypes() {
      return [...new Set(this.tableRecords.map((r) => r.module_type))];
    },

    records() {
      if (this.filteredModuleTypes.length > 0) {
        return this.tableRecords.filter((r) =>
          this.filteredModuleTypes.includes(r.module_type)
        );
      }
      return this.tableRecords;
    },
  },
  watch: {
    focusedLocation(value) {
      if (value) {
        this.getElementsByLocationType();
      }
    },
  },
  async mounted() {
    await this.$store.dispatch('dhmeModuleAssembly/fetchModuleAssemblyData');
  },
  methods: {
    async fetchDrawing(type) {
      this.tableIsLoading = true;
      const { modules } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_MODULES',
            project: this.project.id,
            as: 'modules',
            columns: [
              {
                name: 'module_type',
                conditions: [
                  {
                    operator: '=',
                    value: type,
                  },
                ],
              },
              {
                name: '2d_drawing',
              },
            ],
          },
        ],
      });

      for (const r of modules.records) {
        if (r['2d_drawing']) {
          let file = await downloadDocumentV2(
            modules.id,
            r.id,
            r['2d_drawing'].id
          );

          if (file.file) {
            this.documentPreview =
              URL.createObjectURL(
                this.b64toBlob(file.file, 'application/pdf')
              ) + '#navpanes=0&scrollbar=0&view=FitV';
            this.previewType = r.module_type;
            this.tableIsLoading = false;
            return;
          }
        }
      }
      this.$store.commit('showNotification', {
        content: '2D drawing not found for this module type',
        color: 'info',
      });
      this.tableIsLoading = false;
    },
    b64toBlob(b64Data, contentType) {
      const byteCharacters = atob(b64Data);

      let byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        let slice = byteCharacters.slice(offset, offset + 512),
          byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }
      return new Blob(byteArrays, { type: contentType });
    },
    async generateByPosition() {
      this.isGenerating = true;
      let records = this.generateAndOverwrite
        ? this.tableRecords
        : this.tableRecords.filter((x) => !x.assembly_sequence);

      let data = records.map((r) => {
        return {
          id: r.id,
          module_type: r.module_type,
          element_position: r.element_position,
          assembly_sequence: parseInt(r.element_position.replace(/\./g, '')),
        };
      });

      let book = bookOfObjects('records', data);
      let csv = book.convert('csv', 'string');
      let parsedCsv = btoa(unescape(encodeURIComponent(csv)));

      await importRecordsV2(this.elementSequenceTableId, {
        records: parsedCsv,
      });

      await this.getElementsByLocationType();
      this.isGenerating = false;
      this.closeGenerateMenu();
    },
    closeGenerateMenu() {
      this.generateMenu = false;
      this.generateDoubleCheck = false;
      this.generateAndOverwrite = false;
    },
    getTypesByLocation(locationId) {
      return this.types.filter((t) => t.assembly_location === locationId);
    },
    async saveSequence(item) {
      this.tableIsLoading = true;
      let body = {
        assembly_sequence: item.assembly_sequence
          ? parseInt(item.assembly_sequence)
          : null,
      };
      if (item.id) {
        // update record
        let updatedItem = await updateRecordV2(
          this.elementSequenceTableId,
          item.id,
          body
        );
        Object.assign(
          this.tableRecords.find((r) => r.id === updatedItem.id),
          updatedItem
        );
      } else {
        body.module_type = item.module_type;
        body.element_position = item.element_position;
        // create record
        let newItem = await createRecordV2(this.elementSequenceTableId, body);
        let x = this.tableRecords.find(
          (r) =>
            r.module_type === newItem.module_type &&
            r.element_position === newItem.element_position
        );
        Object.assign(x, {
          id: newItem.id,
          type: newItem.module_type,
          element_category: item.element_category,
          element_position: newItem.element_position,
          assembly_sequence: newItem.assembly_sequence,
        });
      }
      this.tableIsLoading = false;
    },
    async getElementsByLocationType() {
      this.tableIsLoading = true;
      const { elements } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_ELEMENTS',
            project: this.project.id,
            as: 'elements',
            orderBy: 'element_position',
            columns: [
              {
                name: 'element_category',
                conditions: [
                  {
                    operator: '=',
                    values: this.getTypesByLocation(
                      this.focusedLocation.id
                    ).map((t) => t.type),
                  },
                ],
              },
              {
                name: 'module_type',
              },
              {
                name: 'element_position',
              },
            ],
          },
        ],
      });

      const { sequences } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_ELEMENTS_SEQUENCE',
            project: this.project.id,
            as: 'sequences',
            orderBy: 'module_type',
            columns: [
              {
                name: 'element_position',
                conditions: [
                  {
                    operator: '=',
                    values: elements.records.map((e) => e.element_position),
                  },
                ],
              },
              {
                name: 'module_type',
                conditions: [
                  {
                    operator: '=',
                    values: elements.records.map((e) => e.module_type),
                  },
                ],
              },
              {
                name: 'assembly_sequence',
              },
            ],
          },
        ],
      });

      this.elementSequenceTableId = sequences.id;

      let tableRecords = [];
      elements.records.forEach((e) => {
        if (
          !tableRecords.find(
            (r) =>
              r.module_type === e.module_type &&
              r.element_position === e.element_position
          )
        ) {
          let sequence = sequences.records.find(
            (s) =>
              e.module_type === s.module_type &&
              e.element_position === s.element_position
          );
          tableRecords.push({
            ...e,
            assembly_sequence: sequence?.assembly_sequence ?? null,
            id: sequence?.id ?? null,
          });
        }
      });

      tableRecords.sort((a, b) => {
        // Compare module_type
        if (a.module_type < b.module_type) return -1;
        if (a.module_type > b.module_type) return 1;

        // If module_type is the same, compare assembly_sequence
        if (a.assembly_sequence < b.assembly_sequence) return -1;
        if (a.assembly_sequence > b.assembly_sequence) return 1;

        // If assembly_sequence is the same, compare element_position
        return a.element_position - b.element_position;
      });

      this.tableRecords = tableRecords;
      this.tableIsLoading = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
